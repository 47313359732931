

.custom_slider .carousel-control-prev,
.carousel-control-next {
  width: 2%;
}


.custom_slider .carousel-caption {
  width: auto;
  display:inline-table
}


.custom_slider .carousel-caption h1 {
  font-family: 'Cormorant Garamond', serif;
  background-color: rgba(51, 51, 51, 0.7);
  padding: 10px;
  font-size: 40px;
}

.custom_slider .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 3rem;
  left: 2%;
  padding-top: 1rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: left;
}

.custom_slider .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 20px;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin-right: 1.5%;
  margin-bottom: 2rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
}

.custom_slider .carousel-control-prev,
.carousel-control-next {
  width: 3%;

}

.home_about_section {
  background: #dbe6f0 url(../../images/about_bg.jpg) no-repeat;
  background-size: cover;
}


.home_about_section h3 {
  text-align: justify;


}


.style01 {
  color: #919190;
  font-family: "Bauhaus 93 V2" !important;
}

.style01 span {
  color: #2b2a28;
  font-family: "Bauhaus 93 V2" !important;
}

.heading_divider h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 34px;
}

.heading_divider h2:after {
  display: inline-block;
  margin: 0 20px 0px 0;
  height: 20px;
  content: " ";
  text-shadow: none;
  background-color: #999;
  width: 179px;
  background: url(../../images/heading-right-line.jpg) 21px 1px;
  background-repeat: no-repeat;
}


.heading_divider h2:before {
  display: inline-block;
  margin: 0 20px 0px 0;
  height: 20px;
  content: " ";
  text-shadow: none;
  background-color: #999;
  width: 168px;
  background: url(../../images/heading-left-line.jpg) 6px 1px;
  background-repeat: no-repeat;
}


#mouse-scroll {
  display: block;
  cursor: pointer;
}

#mouse-scroll {
  position: absolute;
  margin: auto;
  left: 50%;
  bottom: 50px;
  z-index: 100;
  transform: translateX(-50%);
}


#mouse-scroll span{
  display: block;
  width: 5px; 
  height: 5px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
       transform: rotate(45deg);
       transform: rotate(45deg);
  border-right: 2px solid #fff; 
  border-bottom: 2px solid #fff;
  margin: 0 0 3px 13px;
}

#mouse-scroll .mouse {
  height: 46px;
  width: 30px;
  border-radius: 20px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  border: 2px solid #ffffff;
  top: 170px;
}

#mouse-scroll .down-arrow-1 {
  margin-top: 6px;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
  animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   animation-delay: .1s; 
   -moz-animation-delay: .1s;
   animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  animation-delay: .2s; 
  -moz-animation-delay: .2s;
  animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   animation-delay: .3s;
   -moz-animation-dekay: .3s;
   animation-direction: alternate;
}
#mouse-scroll .mouse-in {
  height: 15px;
  width: 2px;
  display: block; 
  margin: 6px auto;
  background: #ffffff;
  position: relative;
}
#mouse-scroll .mouse-in {
 animation: animated-mouse 1.2s ease infinite;
  animation: mouse-animated 1.2s ease infinite;
}



@keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 960px) {
  .custom_slider .carousel-caption {
    bottom: 0rem;

}
.custom_slider .carousel-caption h1 {
  font-size: 24px;
}


}




@media only screen and (max-width: 660px) {
  .logo {
    font-size: 27px;
  }

  .testimonials_bg {
    background-image: none;
    background-color: #e3e3e3;
  }

  .heading_divider h2:after {
    display: none;
  }

  .heading_divider h2:before {
    display: none;
  }

  .heading_divider h2 {
    font-size: 30px;
  }

  .custom_slider .carousel-caption h1 {
    font-size: 24px;
  }

  .custom_slider .carousel-indicators {
       margin-bottom: 0rem;
  }

  .custom_slider .carousel-caption {
    bottom: 0rem;
    margin-bottom: 0;
    left: 0;
  }




}




.list-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 30px;
  margin-top: 25px;


}

.vid-list .thumbnail {
  width: 100%;
  border-radius: 5px;
}


@media only screen and (max-width: 4110px) {

.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
}


.custom_slider .carousel-indicators {
  bottom: -10px !important;
  margin-bottom: 1rem !important;
}
.custom_slider .carousel-caption h1 {
  font-size: 18px !important;
}

.custom_slider .carousel-caption {
  right: 15%;

  padding-top: 1rem;
  padding-bottom:0rem;

}

.list-container {
 margin-top: 5px; 
}
.heading_divider h2 {
  font-size: 27px;
}


.logo {
  font-size: 24px;
}



}