@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500;600&display=swap');

@font-face {
  font-family: "Bauhaus 93 V2";
  src: url("./fonts/89d11a443c316da80dcb8f5e1f63c86e.eot");
  src: url("./fonts/89d11a443c316da80dcb8f5e1f63c86e.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/89d11a443c316da80dcb8f5e1f63c86e.woff2")
      format("woff2"),
    /* chrome、firefox */ url("./fonts/89d11a443c316da80dcb8f5e1f63c86e.woff")
      format("woff"),
    /* chrome、firefox */ url("./fonts/89d11a443c316da80dcb8f5e1f63c86e.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("./fonts/89d11a443c316da80dcb8f5e1f63c86e.svg#Bauhaus 93 V2")
      format("svg"); /* iOS 4.1- */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cormorant Garamond', serif;
  line-height: 1.1;
}

a,
input,
select,
button,
textarea,
input:focus,
select:focus,
button:focus,
textarea:focus,
*:focus {
  text-decoration: none;
  outline: none;
  color: inherit;
}

:root {
  --blueColor: #005aa9;
  --carousel-button-color: #fff;
  --fancybox-color: #fff;
}


html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Cormorant Garamond', serif;
  color: #333333;
  line-height: 1.4;
  background: var(--fancybox-color);
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 3px;
  background-color: #aaa;
}
::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 2px;
}
::selection {
  background: #222;
  color: #fff;
}
::-moz-selection {
  background: #222;
  color: #fff;
}
::-moz-placeholder {
  color: inherit;
  opacity: 1;
}
::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

.breadcrumb-item.active {
  color: #fff !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #d9e3ec;
}

p {
  font-size: 21px;
  text-align: justify;
}

.breadcrumb-item.active {
  color: #fff;
}


.footer ul li {
  font-size: 21px;
  line-height: 1.2;
}

.single {
  cursor: pointer;
}

.single img {
  max-width: 100%;
}

.single img:hover {
  transform: scale(1.02);
}




.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 1;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover, .btnPrev:hover, .btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 20px;
  right: 40px;
  position: absolute;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 15%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */  

}


.h1, h1 {
  font-size: calc(1.2rem + 1.5vw);
}


.h3, h3 {
  font-size: calc(1.2rem + .6vw);
}