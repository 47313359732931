.social_top_icon a {
  padding-left: 0px;
  color: #464543;
  text-decoration: none;
}

.social_top_icon a {
  vertical-align: middle;
  transition: color 0.3s ease-in-out;
}

.social_top_icon a:nth-child(1):hover {
  color: #146bdb;
}

.social_top_icon a:nth-child(2):hover {
  color: #b42323;
}

.social_top_icon a:nth-child(3):hover {
  color: #b42323;
}

.social_top_icon a:nth-child(4):hover {
  color: #919191;
}
