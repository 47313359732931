.logo {
  font-family: "Bauhaus 93 V2" !important;
  font-size: 36px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  color: #2b2a28;
  margin-bottom: 0;
  padding-bottom: 0;
}

.logo span {
  font-family: "Hahmlet", serif;
  font-size: 16px;
  font-style: normal;
  color: #333;
  margin-top: -10px;
  padding-top: 0;
  text-align: center;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #333;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  font-size: 1.3rem;
  margin:  0 1px;
}
/* .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #2b2a28;
  border: 1px solid transparent;
  border-radius: 0.25rem rem;
  transition: box-shadow 0.15s ease-in-out;
} */

.navbar-expand-lg .navbar-nav .show > .nav-link,
.navbar-expand-lg .navbar-nav .active > .nav-link,
.navbar-expand-lg .navbar-nav .nav-link.show,
.navbar-expand-lg .navbar-nav .nav-link.active,
.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #fff;
  background-color: #2b2a28;
}
